<template>
	<v-layout v-if="isValidated" v-observe-visibility="visibilityChanged" align-center justify-center class="success validation-background">
		<v-tooltip right>
			<template v-slot:activator="{ on }">
				<v-icon dark class="validation-icon" color="success darken-4" v-on="on">done</v-icon>
			</template>
			<span v-t="validationMessage" />
		</v-tooltip>
	</v-layout>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'

export default {
	name: 'ValidationBadge',
	mixins: [DocumentVersionsModuleGuard],
	props: {
		version: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			hasBeenVisible: false,
			validator: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		document: function () {
			return this.model?.is_document ? this.model : null
		},
		currentVersion: function () {
			let result
			if (this.version) {
				result = this.version
			} else {
				result = this.document?.latestVersion
			}
			return result
		},
		isValidated: function () {
			return (
				this.document &&
				((!this.version && this.document.isValidated) || (this.detailsLoaded && this.currentVersion && this.currentVersion.validated_at != null))
			)
		},
		detailsLoaded: function () {
			return this.document && this.document.id && this.document.versions?.length > 0
		},
		validationDate: function () {
			return this.isValidated && this.currentVersion?.validated_at != null ? this.$translateDateTime(this.currentVersion.validated_at, 'short') : null
		},
		validationMessage: function () {
			let result
			if (this.validationDate && this.validator) {
				result = this.$t('documents.validated_long', { date: this.validationDate, user: this.validator })
			} else if (this.validationDate && !this.validator) {
				result = this.$t('documents.validated_at', { date: this.validationDate })
			}
			return result
		}
	},
	watch: {
		hasBeenVisible: {
			immediate: true,
			handler: function (newHasBeenVisible) {
				if (newHasBeenVisible) {
					return Promise.all([this.loadDetails(), this.loadValidator()])
				}
			}
		}
	},
	methods: {
		visibilityChanged: function (isVisible) {
			if (!this.hasBeenVisible && isVisible) {
				this.hasBeenVisible = isVisible
			}
		},
		loadDetails: function () {
			let result = Promise.resolve()
			if (!this.detailsLoaded) {
				result = this.service.getDocumentVersions(this.vendorId, this.document)
			}
			return result
		},
		loadValidator: function () {
			let result = Promise.resolve()
			if (this.isValidated && this.currentVersion) {
				result = this.service
					.getDocumentVersionValidator(this.vendorId, this.document.id, this.currentVersion)
					.catch(() => {
						this.validator = null
					})
					.then(documentVersionValidator => {
						if (documentVersionValidator?.username) {
							this.validator = documentVersionValidator.username
						} else {
							this.validator = null
						}
					})
			}
			return result
		}
	}
}
</script>
<style scoped>
.validation-background {
	border-radius: 50%;
	width: 80%;
	height: 80%;
}
.validation-icon {
	font-size: 80%;
}
</style>
