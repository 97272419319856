import { render, staticRenderFns } from "./ValidationBadge.vue?vue&type=template&id=82b151e2&scoped=true"
import script from "./ValidationBadge.vue?vue&type=script&lang=js"
export * from "./ValidationBadge.vue?vue&type=script&lang=js"
import style0 from "./ValidationBadge.vue?vue&type=style&index=0&id=82b151e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82b151e2",
  null
  
)

export default component.exports